module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Jalaj Gupta","short_name":"Jalaj Gupta","description":"Fullstack Web and Mobile Developer. This is my portfolio and personal blog.","start_url":"/","background_color":"white","theme_color":"#5183f5","display":"minimal-ui","icon":"static/logo.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"3092422ff79ff8ed151aa11a74f740d9"},
    },{
      plugin: require('../node_modules/gatsby-plugin-theme-switcher/gatsby-browser.js'),
      options: {"plugins":[],"defaultDarkTheme":"theme-dark","defaultLightTheme":"theme-light","minify":true,"themeStorageKey":"theme"},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"classPrefix":"language-","inlineCodeMarker":null,"aliases":{},"showLineNumbers":true,"noInlineHighlight":true,"prompt":{"user":"root","host":"localhost","global":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"tomato","showSpinner":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
